/* Estilos para a barra de rolagem */
*::-webkit-scrollbar {
    width: 10px; /* Largura da barra de rolagem */
}

*::-webkit-scrollbar-track {
    background: #1f2937; /* Cor de fundo da trilha */
}

*::-webkit-scrollbar-thumb {
    background-color: #9333ea; /* Cor do polegar da barra de rolagem */
    border-radius: 10px; /* Cantos arredondados */
    border: 3px solid #1f2937; /* Espaçamento interno */
}

*::-webkit-scrollbar-thumb:hover {
    background: #9333ea; /* Cor ao passar o mouse */
}

/* Para navegadores que suportam scrollbars nativos */
* {
    scrollbar-color: #9333ea #1f2937; /* Polegar e trilha */
    scrollbar-width: thin; /* Largura da barra de rolagem */
}
